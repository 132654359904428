<template>
  <section>
    <title-section
      title="Preguntas frecuentes"
    >
      <div class="level-item">
        <button class="button button_micro_dark" @click="activeModalAddFaq = true">
          AGREGAR PREGUNTA
        </button>
      </div>
    </title-section>
    <frequent-questions-main
      :activeModalAddFaq="activeModalAddFaq"
      @close-modal="activeModalAddFaq = false"
    />
  </section>
</template>

<script>
export default {
  name: 'FrequentQuestions',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    FrequentQuestionsMain: () => import('@/components/FrequentQuestions/FrequentQuestionsMain.vue')
  },
  data () {
    return {
      activeModalAddFaq: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 15px;
    width: 200px;
  }
  .button_micro_dark_outlined {
    border: solid 2px #262626;
  }
</style>
